<template>
    <div>
        <div class="row">
            <div class="col col-8">
            <div class="font-weight-bold">{{ fullName }}</div>
            </div>
            <div class="col col-4 p-0" v-if="!viewMode">
                <div class="card-action-group">
                    <div class="card-action" @click="$emit('edit', {item, index})">
                        <i class="fas fa-edit"></i>
                    </div>
                    <div class="card-action" @click="$emit('delete', {item, index})">
                        <i class="fas fa-trash"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="font-weight-bold mb-5">{{ userType }}</div>
        <div class="mb-5" v-if="!isForeigner">
            <span class="font-weight-bold">{{ $t("WITNESS.CARD.PERSONAL_NUMERIC_CODE") }}: </span>
            <span>{{ item.personal_numeric_code}}</span>
        </div>
        <div class="mb-5" v-if="isForeigner">
            <span class="font-weight-bold">{{ $t("WITNESS.CARD.PASSPORT_NUMBER") }}: </span>
            <span>{{ item.passport_number}}</span>
        </div>
        <div class="mb-5">
            <div class="font-weight-bold">{{ $t("WITNESS.CARD.ADDRESS") }}:</div>
            <div>{{ addressComputed }}</div>
        </div>
        <div class="row mb-5">
            <div class="col col-6">
                <div class="font-weight-bold">{{ $t("WITNESS.CARD.CONTACT") }}:</div>
                <div>{{ item.phone_number }}</div>
            </div>
            <div class="col col-6">
                <div class="font-weight-bold">{{ $t("WITNESS.CARD.CITIZENSHIP") }}:</div>
                <div>{{ citizenship }}</div>
            </div>
        </div>
        <div>{{ item.email }}</div>
    </div>
</template>
<script>
export default {
    name: 'OffenderItemCard',
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        viewMode: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        addressComputed() {
            let address = '';
            if (this.item.county) address += `${this.$t('WITNESS.CARD.ADDRESS_COUNTY')} ${this.item.county}`;
            if (this.item.country) address += this.item.country;
            if (this.item.city) address += `, ${this.item.city}`;
            if (this.item.address) address += `, ${this.item.address}`;
            if (this.item.locality) address +=  `, ${this.item.locality}`;
            if (this.item.street) address += `, ${this.$t('WITNESS.CARD.ADDRESS_STREET')} ${this.item.street}`;
            if (this.item.street_number) address += `, ${this.$t('WITNESS.CARD.ADDRESS_NUMBER')} ${this.item.street_number}`;
            if (this.item.staircase) address += `, ${this.$t('WITNESS.CARD.ADDRESS_STAIRCASE')} ${this.item.staircase}`;
            if (this.item.apartment) address += `, ${this.$t('WITNESS.CARD.ADDRESS_APARTMENT')} ${this.item.apartment}`;
            return address;
        },
        userType() {
            const userType = this.item?.user_type_name === "legal_entity" ? "LEGAL" : "INDIVIDUAL";
            return this.$t(`WITNESS.DETAILS.PERSON.${userType}`);
        },
        citizenship() {
            const userType = this.item?.user_type_name === "foreigner" ? "FOREIGNER" : "ROMANIAN";
            return this.$t(`WITNESS.DETAILS.CITIZENSHIP.${userType}`)
        },
        fullName() {
            let name = '';

            if (this.item.first_name) name += this.item.first_name;
            if (this.item.last_name) name += ` ${this.item.last_name}`;
            return name;
        },
        isForeigner() {
            return this.item?.user_type_name == "foreigner";
        }
    }
}
</script>