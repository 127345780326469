<template>
    <div class="form-group" id="st_witness_section">
        <WitnessFormTypes
            ref="witness"
            :viewMode="viewMode"
            :item="selectedEntity"
            @submit="submit"
            @cancel="cancel"
        >
            <template #buttons="{ submit, cancel }">
                <div class="d-flex justify-content-between">
                    <st-button size="large" variant="link" :callback="cancel">
                        {{ $t('CONTROL.MODALS.CANCEL') }}
                    </st-button>
                    <st-button size="large" :callback="submit">
                          {{ submitButtonText }}
                    </st-button>
                </div>
            </template>
        </WitnessFormTypes>
    </div>
</template>

<script>
import WitnessFormTypes from "./WitnessFormTypes";
import { mapGetters, mapActions } from "vuex";
import { generateRandomId } from '@/core/helpers/globalMethods';

export default {
    name: "WitnessForm",
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false
        },
        items: {
            type: Array,
        },
    },
    data() {
        return {
            selectedEntity: null
        };
    },
    components: {
        WitnessFormTypes,
    },
    computed: {
        ...mapGetters({
            record: "control/form/record",
        }),
        submitButtonText() {
            return this.item ? this.$t('CONTROL.MODALS.SAVE') : this.$t('CONTROL.MODALS.ADD');
        },
    },
    methods: {
        ...mapActions({
            doAddControlPerson: 'control/form/doAddControlPerson',
            doUpdateControlPerson: 'control/form/doUpdateControlPerson',
        }),
        onModelChange(model) {
            this.$emit("change", model);
        },
        async validateData() {
            const fieldRefs = this.$refs.witness.$refs;
            const selectedEntityKey = Object.keys(fieldRefs).find(
                (key) => !!fieldRefs[key]
            );
            const selectedEntity = fieldRefs[selectedEntityKey];

            let status = await selectedEntity.fv.validate();
            let details = this.fillWitnessModel(selectedEntity);

            const witness_id = this.item?.witness_id || generateRandomId();
            const witnessDetails = { ...selectedEntity.model, ...details, witness_id };
            const isPersonalNumericCodeUsed = this.checkUniquePersonalNumericCode(this.items, witnessDetails);
            const isPassportUsed = this.checkPassport(this.items, witnessDetails);
            if (isPersonalNumericCodeUsed) {
                this.$alert({
                    type: 'warning',
                        text: this.$t('CONTROL.NOTIFIERS.DUPLICATE_WITNESS_PNC', {pnc: witnessDetails.personal_numeric_code}),
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: this.$t('GENERAL.BUTTON.UPDATE'),
                    });
                return { status: 'Invalid'};
            }

            if (isPassportUsed) {
                this.$alert({
                    type: 'warning',
                        text: this.$t('CONTROL.NOTIFIERS.DUPLICATE_WITNESS_PASSPORT', {passport: witnessDetails.passport_number}),
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: this.$t('GENERAL.BUTTON.UPDATE'),
                    });
                return { status: 'Invalid'};
            }

            return {
                status,
                witnessDetails,
            };
        },
        fillWitnessModel(entity) {
            if (entity.model.user_type_name === "foreigner") {
                return {};
            }

            let details = {
                locality: entity.fields.locality_id.options.find((el) => el.value === entity.model.locality_id)?.text || "",
                county: entity.fields.county_id.options.find((el) => el.value === entity.model.county_id)?.text || "",
            };

            if (entity.model.user_type_name === "citizen") {
                details.village = entity.fields.village_id.options.find((el) => el.value === entity.model.village_id)?.text || "";
            }

            return details;
        },
        async submit({ isPredefined, saveAsPredefined }) {
            const witness = await this.validateData();
            
            if (witness.status === 'Valid') {
                if (this.item) {
                    witness.index = this.item.index;
                }

                if (saveAsPredefined) {
                    if (!isPredefined) {
                        witness.witnessDetails = await this.doAddControlPerson(witness.witnessDetails);
                    } else {
                        witness.witnessDetails = await this.doUpdateControlPerson(witness.witnessDetails);
                    }
                }

                this.$emit("submitForm", witness);
            }
        },
        cancel() {
            this.$emit("cancelForm");
        },
        checkUniquePersonalNumericCode(witnesses, witnessDetails) {
            return witnesses
                .filter(el => el.witness_id !== witnessDetails.witness_id)
                .some((el) => witnessDetails.personal_numeric_code && el.personal_numeric_code === witnessDetails.personal_numeric_code);
        },
        checkPassport(witnesses, witnessDetails) {
            return witnesses
                .filter(el => el.witness_id !== witnessDetails.witness_id)
                .some((el) => witnessDetails.passport_number && el.passport_number === witnessDetails.passport_number);
        }
    },
    created() {
        this.selectedEntity = this.item || null;
    },
};
</script>
