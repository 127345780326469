<template>
    <div class="mb-4">
        <div class="row justify-content-start mb-6 align-items-center">
            <div class="col-4">
                <label>
                    {{ fields.control_person.label }}
                </label>
                <st-autocomplete
                    v-model="model[fields.control_person.name]"
                    :options="controlPersons"
                    :name="fields.control_person.name"
                    :disabled="viewMode"
                    :fields="fields"
                    @change="getControlPerson"
                >
                </st-autocomplete>
            </div>
        </div>
        <div class="row justify-content-start mb-4">
            <div class="col-4">
                <p class="font-size-h6">
                    {{ $t("WITNESS.DETAILS.CITIZENSHIP.HEADER") }}
                </p>
                <b-form-radio-group
                    size="lg"
                    v-model="selectedCitizenship"
                    :options="citizenship"
                    name="radio-options-citizenship"
                    :disabled="viewMode"
                    @change="changeCitizenship"
                ></b-form-radio-group>
            </div>
        </div>

        <div v-if="selectedCitizenship === 'foreigner'">
            <foreigner-witness-form
                :item="selectedWitness"
                ref="foreignerWitness"
                :viewMode="viewMode"
            >
            </foreigner-witness-form>
        </div>
        <div v-else>
            <citizen-witness-form
                :item="selectedWitness"
                ref="citizenWitness"
                :viewMode="viewMode"
            >
            </citizen-witness-form>
        </div>

        <div class="mt-8">
            <b-form-checkbox
                v-model="saveAsPredefined"
                size="lg"
                :disabled="viewMode"
            >
                {{ $t("WITNESS.DETAILS.SAVE_AS_PREDEFINED") }}
            </b-form-checkbox>
        </div>


        <div class="mt-6">
            <slot name="buttons" :submit="submit" :cancel="cancel" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CitizenWitnessForm from "./CitizenWitnessForm";
import ForeignerWitnessForm from "./ForeignerWitnessForm";
import { PersonModel } from "@/modules/control/models/person-model";
import { FormSchema } from "@/shared/form/form-schema";

const { fields } = PersonModel;

const formSchema = new FormSchema([
    fields.control_person
]);

export default {
    name: "WitnessFormTypes",
    components: {
       CitizenWitnessForm,
       ForeignerWitnessForm,
    },
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
        },
    },
    data() {
        return {
            citizenship: [
                {
                    text: this.$t("WITNESS.DETAILS.CITIZENSHIP.ROMANIAN"),
                    value: "citizen",
                },
                {
                    text: this.$t("WITNESS.DETAILS.CITIZENSHIP.FOREIGNER"),
                    value: "foreigner",
                },
            ],
            selectedCitizenship: "citizen",
            selectedWitness: null,
            fields,
            model: null,
            selectedPerson: null,
            saveAsPredefined: false,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "auth/currentUser",
            record: "control/form/record",
        }),
        controlPersons() {
            return this.fields?.control_person?.options?.filter((el) => el.user_type_name === this.selectedCitizenship);
        }
    },
    async created() {
        this.selectedWitness = this.item;
        this.model = formSchema.initialValues({});
        if (this.item?.id) {
            this.model[this.fields.control_person.name] = this.item.id;
        }
    },
    async mounted() {
        if (this.viewMode || this.item) {
            this.selectedCitizenship = this.item.user_type_name !== "foreigner" ? "citizen" : "foreigner";
        }
    },
    methods: {
        ...mapActions({
            findControlPerson: 'control/form/doFindControlPerson'
        }),
        async submit() {
            this.$emit("submit", { isPredefined: this.model[this.fields.control_person.name], saveAsPredefined: this.saveAsPredefined });
        },
        cancel() {
            this.$emit("cancel");
        },
        async getControlPerson() {
            this.selectedWitness = await this.findControlPerson(this.model[this.fields.control_person.name]);
        },
        changeCitizenship() {
            this.model[this.fields.control_person.name] = '';
            this.selectedWitness = formSchema.initialValues({});
            this.saveAsPredefined = false;
        },
    },
};
</script>
