<template>
    <form class="form"
        novalidate="novalidate"
        id="st_foreigner_form"
    >
        <div class="row mb-6">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.passport_number.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.passport_number.name]"
                            :ref="fields.passport_number.name"
                            :name="fields.passport_number.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.id_card_release_date.label }}
                    </label>
                    <div>
                        <st-datepicker
                            v-model="model[fields.id_card_release_date.name]"
                            :ref="fields.id_card_release_date.name"
                            :name="fields.id_card_release_date.name"
                            :disabled="viewMode"
                            :max="max"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.last_name.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.last_name.name]"
                            :ref="fields.last_name.name"
                            :name="fields.last_name.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.first_name.label }} *
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.first_name.name]"
                            :ref="fields.first_name.name"
                            :name="fields.first_name.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ $t('SET_PROFILE_DATA.FOREIGNER.COUNTRY') }}
                    </label>
                    <div>
                        <st-autocomplete
                            v-model="model[fields.country.name]"
                            :options="fields.country.options"
                            :ref="fields.country.name"
                            :name="fields.country.name"
                            value-field="text"
                            text-field="text"
                            :disabled="viewMode"
                        ></st-autocomplete>
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.city.label }}
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.city.name]"
                            :ref="fields.city.name"
                            :name="fields.city.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label>
                        {{ fields.address.label }}
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.address.name]"
                            :ref="fields.address.name"
                            :name="fields.address.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.email.label }}
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.email.name]"
                            :ref="fields.email.name"
                            :name="fields.email.name"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label>
                        {{ fields.phone_number.label }}
                    </label>
                    <div>
                        <st-input-text
                            v-model="model[fields.phone_number.name]"
                            :ref="fields.phone_number.name"
                            :name="fields.phone_number.name"
                            :disabled="viewMode"
                            phone-validation
                        />
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { mapGetters } from 'vuex';
import { PersonModel } from "@/modules/control/models/person-model";
import { FormSchema } from '@/shared/form/form-schema';
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { idCard } from '@/shared/utils/id-card';

const { fields } = PersonModel;

const formSchema = new FormSchema([
    fields.id,
    fields.user_type_name,
    fields.passport_number,
    fields.first_name,
    fields.last_name,
    fields.country,
    fields.city,
    fields.address,
    fields.email,
    fields.phone_number,
    fields.id_card_release_date
]);

export default {
    name: 'ForeignerWitnessForm',
    components: {},
    props: {
        viewMode: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object
        }
    },
    data() {
        return {
            fields,
            model: null,
            rules: formSchema.rules(),
            max: idCard.releaseDate,
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
            localities: 'profile/settlements',
            villages: 'profile/villages',
            record: "control/form/record"
        })
    },
    async created() {
        const record = this.getInitialModelValues();
        this.model = formSchema.initialValues(record);

        if (!this.viewMode) {
            this.model.user_type_name = 'foreigner';
        }
    },
    mounted() {
        this.fv = createFormValidation('st_foreigner_form', this.rules);
    },
    methods: {
        getInitialModelValues() {
            const userType = {
                user_type_name: 'foreigner'
            }

            if ((this.viewMode || (this.record?.status === 'draft')) && this.item) {
                return {
                    ...this.item,
                };
            } else {
                if (this.item) {
                    return { ...this.item, ...userType};
                }

                return userType;
            }
        },
    },
    watch: {
        item: {
            deep: true,
            handler(item) {
                const { user_type_name } = this.model;
                this.model = {
                    ...item,
                    user_type_name,
                };
            }
        }
    },
}
</script>
